<template>
  <div>
    <list-layout ref="govSubject" :filter-form="filterForm" :thead="thead"
                 :on-fetch="getList" @command="handleOperation"
    >
      <template #filter="{pageData}">
        <subject-list-filter is-gov :filter-form="filterForm" :filter-change="ok" :export-data="{}" />
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "@/base/layout/ListLayout";
import { getGovTopics } from "@/modules/gov/api/topic-library";
import SubjectListFilter from "@/modules/article-admin/components/article-subject/index/Filter";
import { getGovSubjects } from "@/modules/gov/api/subject-library";
export default {
  components: {SubjectListFilter, ListLayout},
  data() {
    return {
      filterForm: {
        tab: "normal",
        keyword: "", //专题名称
        category_id: -1, //专题分类
        category_name: '',
        author_name: "", //创建人
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        project_id: this.$store.getters.tenant.id || ''
      },
      thead: [
        {label: "所属商协会", prop: "project_name", minWidth: 100},
        { label: "专题名称", prop: "name", minWidth: 240, tooltip: true },
        { label: "分类", prop: "category_name", minWidth: 100 },
        { label: "栏目数", prop: "columns_count", minWidth: 100 },
        { label: "文章数", prop: "articles_count", minWidth: 100 },
        { label: "浏览数", prop: "view_count", minWidth: 100 },
        { label: "创建人", prop: "author_name", minWidth: 100 },
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 180,
          sort: "custom",
        },
      ],
      detailDialog: {
        show: false,
        data: {}
      }
    }
  },
  methods: {
    handleOperation(e) {
      // switch(e.command) {
      //     default:
      // }
    },
    getList(q) {
      return getGovSubjects(q)
    },
    // 筛选回调
    ok(e) {
      const pageData = { ...this.filterForm, ...e, page: 1 };
      this.$refs.govSubject.getList(pageData)
    },
  },
}
</script>

<style scoped>

</style>
