/*
 * @Author: mulingyuer
 * @Date: 2021-09-14 18:07:57
 * @LastEditTime: 2021-09-29 16:08:55
 * @LastEditors: aleaner
 * @Description: 资讯列表
 * @FilePath: \gov\api\news-library\index.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";


//来源选项数据
export const sourcesList = data => {
  return api({
    url: "/admin/gov/article/sources",
    method: "post",
    data
  })
}

//分类选项数据
export const categoriesList = data => {
  return api({
    url: "/admin/gov/article/categories",
    method: "post",
    data
  })
}


//资讯列表
export const list = data => {
  return api({
    url: "/admin/gov/article/index",
    method: "post",
    data
  })
}

// 同步文章列表
export const createArticles = data => {
  return api({
    url: "/admin/gov/article/createArticles",
    method: "post",
    data
  })
}

//获取所有分类数据
export const allClassifyData = data => {
  return api({
    url: "/admin/portal/category/all",
    method: "post",
    data
  });
};