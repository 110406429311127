/*
 * @Author: mulingyuer
 * @Date: 2021-05-25 11:11:30
 * @LastEditTime: 2021-06-11 17:33:56
 * @LastEditors: aleaner
 * @Description:专题管理
 * @FilePath: \article-admin\api\article-subject\index.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";
import download from "@/base/utils/download";

//专题管理列表
export const subjectList = data => {
  return api({
    url: "/admin/portal/subject/index",
    method: "post",
    data,
  })
};

//专题管理-分类选项数据，和文章分类是一样的
export const subjectCategory = data => {
  return api({
    url: "/admin/portal/category/all",
    method: "post",
    data,
  })
};

//专题管理-导出
export const exportSubject = data => {
  return download({
    url: "/admin/portal/subject/excelSubjects",
    method: "post",
    data,
    download: true,
  })
};

//专题管理-软删除
export const softDelete = data => {
  return api({
    url: "/admin/portal/subject/softDelete",
    method: "post",
    data,
  })
};

//专题管理-硬删除
export const hardDelete = data => {
  return api({
    url: "/admin/portal/subject/del",
    method: "post",
    data,
  })
};

//专题管理-还原
export const putBack = data => {
  return api({
    url: "/admin/portal/subject/putBack",
    method: "post",
    data,
  })
};


//专题管理-还原
export const changeStatus = data => {
  return api({
    url: "/admin/portal/subject/changeStatus",
    method: "post",
    data,
  })
};


//专题管理-栏目数据
export const columnsData = data => {
  return api({
    url: "/admin/portal/subject/columns",
    method: "post",
    data,
  })
};

// 专题置顶
export const setTop = data => {
  return api({
    url: "/admin/portal/subject/top",
    method: "post",
    data
  })
};

// 取消置顶
export const cancelTop = data => {
  return api({
    url: "/admin/portal/subject/cancelTop",
    method: "post",
    data
  })
};

// 更新专题排序
export const updateSort = data => {
  return api({
    url: "/admin/portal/subject/sort",
    method: "post",
    data
  })
};
