<!--
 * @Author: mulingyuer
 * @Date: 2021-05-25 10:43:22
 * @LastEditTime: 2021-09-09 09:48:57
 * @LastEditors: aleaner
 * @Description: 专题管理-筛选
 * @FilePath: \article-admin\components\article-subject\index\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :onFilter="onFilter" :onReset="onReset" :onExport="isGov?null:onExport" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="70px" size="medium" class="filter-from">
      <div class="filter-item">
        <el-form-item label="专题名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入专题名称"></el-input>
        </el-form-item>
        <el-form-item v-if="!isGov" label="专题分类：" prop="category_id">
          <el-select v-model="form.category_id" placeholder="请选择专题分类">
            <el-option v-for="op in categoryArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-else label="专题分类：" prop="category_name">
          <el-select v-model="form.category_name" placeholder="请选择专题分类">
            <el-option v-for="op in categoryArr" :key="op.value" :label="op.name" :value="op.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建人：" prop="author_name">
          <el-input v-model="form.author_name" placeholder="请输入创建人"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="创建时间：" start-placeholder="开始日期" :start-time.sync="form.start_time"
          start-prop="start_time" end-placeholder="结束日期" :end-time.sync="form.end_time" end-prop="end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
  import LayoutFilter from "@/base/layout/LayoutFilter";
  import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
  import {exportSubject, subjectCategory} from "../../../api/article-subject/index";
  import { categoriesList } from "@/modules/gov/api/news-library";

  export default {
    name: 'subject-list-filter',
  props: {
    //筛选回调
    filterChange: {
      type: Function,
      required: true,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
    filterForm: Object,
    isGov: Boolean,
  },
  data() {
    return {
      form: {
        keyword: "", //专题名称
        category_id: -1, //专题分类
        author_name: "", //创建人
        start_time: -1, //开始时间
        end_time: -1, //结束时间
      },
      rules: {},
      //分类选项数据
      categoryArr: [{ id: -1, name: "全部" }],
    };
  },
  methods: {
    //获取分类数据
    getSubjectCategory() {
if (this.isGov) {
  categoriesList().then(res => {
    const { data } = res;

    const uniqueArr = data.reduce((acc, curr) => {
      // 重复的key取第一次出现的值
      if (!acc.find(el => el.name === curr.name)) {
        acc.push(curr)
      }
      return acc;
    }, []);

    this.categoryArr = [...uniqueArr]
  }).catch(err => {
  })
  return
}
      subjectCategory()
        .then((res) => {
          const { data } = res;
          this.categoryArr.push(...data);
        })
        .catch(() => {});
    },
    //筛选
    onFilter() {
      return this.filterChange(Object.assign(this.filterForm || {}, this.form));
    },
    //重置
    onReset() {
      this.$refs["ruleForm"].resetFields();
      return this.onFilter();
    },
    //导出
    onExport() {
      return exportSubject({
        ...this.exportData,
        ...this.form,
      }).catch(() => {});
    },
  },
  created() {
    this.getSubjectCategory();
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

<style lang="scss" scoped>
</style>
